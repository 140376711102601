import React, { useEffect } from "react";

const Chatbot = () => {
  useEffect(() => {
    const chatbotScriptId = "cxgenie-chatbot-script"; // Unique ID for the script

    // Function to add the chatbot script
    const addChatbotScript = () => {
      if (!document.getElementById(chatbotScriptId)) {
        const script = document.createElement("script");
        script.src = "https://widget.cxgenie.ai/widget.js";
        script.async = true;
        script.id = chatbotScriptId; // Assign the ID to prevent duplicates
        script.setAttribute("data-aid", "d175830a-e8d9-4604-9f30-22d4e66dc58f");
        script.setAttribute("data-lang", "en");
        document.body.appendChild(script);
      }
    };

    // Function to remove the chatbot script
    const removeChatbotScript = () => {
      const script = document.getElementById(chatbotScriptId);
      if (script) {
        script.remove();
      }

      // Remove chatbot-related widgets or icons if necessary
      const widgets = document.querySelectorAll("[class*=cxgenie]");
      widgets.forEach((widget) => widget.remove());
    };

    // Add the script when the component mounts
    addChatbotScript();

    // Cleanup the script when the component unmounts
    return () => removeChatbotScript();
  }, []); // Empty dependency array ensures this runs only once

  return null; // No visible JSX needed
};

export default Chatbot;
