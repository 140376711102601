import React, {useState} from 'react'
import HomeNavbar from "./components/CommonHomePage/HomeNavbar/HomeNavbar";
import HomeFooter from "./components/CommonHomePage/HomeFooter/HomeFooter";
import StickyFooter from './components/CommonHomePage/StickyFooter/StickyFooter';

// ----------------------------------------------------------------------------------------------------------

const MeetingPage = () => {
    const [isDarkMode, setIsDarkMode] = useState(true);

  const handleToggleDarkMode = (darkMode) => {
    setIsDarkMode(darkMode);
  };
  return (
    <div>
        <HomeNavbar onToggleDarkMode={handleToggleDarkMode}/>
        <div style={{ width: '100%', height: '100vh', paddingTop: '60px'}}>
            <iframe src='https://outlook.office365.com/owa/calendar/GetFreeOnlineLegalAdviceToday@lawcrats.com/bookings/' width='100%' height='100%' style={{ border: 'none'}}></iframe>
        </div>
        <HomeFooter isDarkMode={isDarkMode}/>
        <StickyFooter isDarkMode={isDarkMode} />
    </div>
  )
}

export default MeetingPage