import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
import { useNavigate } from 'react-router-dom';
import Button from '@mui/material/Button';
import useMediaQuery from '@mui/material/useMediaQuery';
import aila from "../assets/AILAWebM.webm"

import NavigateNextIcon from '@mui/icons-material/NavigateNext';

const useStyles = makeStyles({
  card: {
    display: 'flex',
    width: '100%',
    height: '450px',
    backgroundColor: '#f5f5f5',
    borderRadius: '8px',
    overflow: 'hidden',
    marginBottom: '20px',
    '@media (max-width: 1080px)': {
      flexDirection: 'column',
      height: 'auto', // Adjust height for stacked layout
    },
  },
  gifContainer: {
    width: '55%',
    objectFit: 'cover',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#000',
    '@media (max-width: 1080px)': {
      width: '100%',
    },
    '@media (max-width: 600px)': {
      width: '100%',
      height: '250px',
    },
  },
  gif: {
    width: '92%',
    height: '90%',
    objectFit: 'cover',
  },
  
  descriptionContainer: {
    width: '45%',
    padding: '50px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    backgroundColor: '#fff',
    alignItems: 'flex-start',
    '@media (max-width: 1080px)': {
      width: '100%',
      padding: '30px',
    },
  },
  title: {
    fontSize: '1.6em',
    fontWeight: '600',
    fontFamily: 'Montserrat, sans-serif',
    marginBottom: '20px',
    color: '#555',
    '@media (max-width: 600px)': {
      fontSize: '18px',
    },

  },
  description: {
    fontSize: '16px',
    color: '#555',
    textAlign: 'justify',
    textJustify: 'inter-word',
    marginBottom: '15px',
    '@media (max-width: 600px)': {
      fontSize: '14px',
    },
  },
});

const ProductShowcaseCard = ({ gifSrc, title, description, redirectURL, atLeftSide }) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const isSmallScreen = useMediaQuery('(max-width:1080px)');

  const handleRedirect = () => {
    navigate(redirectURL);
  };

  return (
    <div className={classes.card}>
      {(atLeftSide && !isSmallScreen) || isSmallScreen ? (
        <>
          <div className={classes.gifContainer}>
            {/* <video src={gifSrc} alt="Product GIF" className={classes.gif} /> */}
            <video src={gifSrc} autoPlay loop muted playsInline className={classes.gif}>
              Your browser does not support the video tag.
            </video>
          </div>
          <div className={classes.descriptionContainer}>
            <h2 className={classes.title}>{title}</h2>
            <p className={classes.description}>{description}</p>
            <Button variant="text" sx={{ textTransform: 'none', paddingLeft: '0px', fontSize: '16px', alignItems: 'center', justifyContent: 'center', color: '#555' }} onClick={handleRedirect} >Explore <NavigateNextIcon sx={{width: '20px'}} /></Button>
          </div>
        </>
      ) : (
        <>
          <div className={classes.descriptionContainer}>
            <h2 className={classes.title}>{title}</h2>
            <p className={classes.description}>{description}</p>
            <Button variant="text" sx={{ textTransform: 'none', paddingLeft: '0px', fontSize: '16px', alignItems: 'center', justifyContent: 'center', color: '#555' }} onClick={handleRedirect} >Explore <NavigateNextIcon sx={{width: '20px'}} /></Button>
          </div>
          <div className={classes.gifContainer}>
            {/* <video src={gifSrc} alt="Product GIF" className={classes.gif} /> */}
            <video src={gifSrc} autoPlay loop muted playsInline className={classes.gif}>
              Your browser does not support the video tag.
            </video>
          </div>
        </>
      )}
    </div>
  );
};

ProductShowcaseCard.propTypes = {
  gifSrc: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  redirectURL: PropTypes.string.isRequired,
  atLeftSide: PropTypes.bool.isRequired,
};

export default ProductShowcaseCard;
