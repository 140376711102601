import React, { useState } from "react";
import "./HomeFooter.css";
import PrivacyPolicyModal from "../../../CommonFiles/PrivacyPolicyModal";
import TermsOfServiceModal from "../../../CommonFiles/TermsOfServiceModal";
import { Flex, message } from "antd";
import axios from "axios";
import LaunchIcon from "@mui/icons-material/Launch";
import { useNavigate } from "react-router-dom";

const HomeFooter = ({ isDarkMode }) => {
  const [openPrivacyPolicy, setOpenPrivacyPolicy] = useState(false);
  const [openTermsOfService, setOpenTermsOfService] = useState(false);
  const [email, setEmail] = useState(null);
  const token = localStorage.getItem("lawCratsToken");

  const navigate = useNavigate();

  const handlePrivacyPolicy = () => {
    setOpenPrivacyPolicy(!openPrivacyPolicy);
  };
  const handleTermsOfService = () => {
    setOpenTermsOfService(!openTermsOfService);
  };

  const handleNewsletter = async () => {
    // Regex pattern for validating email format
    const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

    if (!email) {
      return message.warning("Please enter your Email.");
    } else if (!emailPattern.test(email)) {
      return message.warning(
        "Invalid email format. Please enter a valid email."
      );
    } else {
      try {
        const response = await axios.post(
          "https://d3gcxrg9olqhec.cloudfront.net/api/news-letter-data-saving/",
          { email: email },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );

        if (response.status === 200) {
          message.success("Subscribed Successfully!");
        }
      } catch (error) {
        if (error.response && error.response.data.message) {
          if (error.response.data.message === "You have already subscribed!") {
            message.warning("You have already subscribed!");
          } else {
            message.error(error.response.data.message);
          }
        } else {
          message.error("Something went wrong. Try Again!");
        }
      }
    }
  };

  const handlePrivacyPolicyPageOpen = () => {
    navigate("/privacy-policy");
  };

  const handleTermsOfServicePageOpen = () => {
    navigate("/terms-of-service");
  };

  return (
    <div className={`footer ${isDarkMode ? "dark" : ""}`}>
      <div className="footer_top">
        <div className="left_footer">
          <div className="security-compliance-section">
            <div className="security-text">
              <p>We take security and privacy seriously</p>
            </div>
            <div className="compliance-cards">
              {["SOC 2 Type II", "ISO 27001", "GDPR", "CCPA"].map(
                (label, index) => (
                  <div key={index} className="compliance-card">
                    <span className="tick-icon">✔</span>
                    <p>{label}</p>
                  </div>
                )
              )}
            </div>
          </div>

          <h1>
            <span
              className="law"
              style={{ color: isDarkMode ? "white" : "black" }}
            >
              Law
            </span>
            <span className="crats">Crats</span>
          </h1>
          <div className="form">
            <p>Subscribe to Our Newsletter</p>
            <div className="form1">
              <input
                type="email"
                placeholder="Your email"
                onChange={(e) => setEmail(e.target.value)}
              />
              <button onClick={handleNewsletter} style={{ cursor: "pointer" }}>
                Subscribe
              </button>
            </div>
          </div>

          <div className="icons">
            <div className="face">
              <a
                href="https://www.facebook.com/people/LawCrats-The-NextGen-Techno-Lawyers/61559933638363/"
                className="icon-a"
              >
                <i className="fa-brands fa-facebook-f"></i>
              </a>
            </div>
            <div className="face">
              <a href="https://www.instagram.com/lawcrats/" className="icon-a">
                <i className="fa-brands fa-instagram"></i>
              </a>
            </div>
            <div className="face">
              <a href="https://x.com/LawcratsTeam" className="icon-a">
                <i className="fa-brands fa-twitter"></i>
              </a>
            </div>
            <div className="face">
              <a
                href="https://www.linkedin.com/company/lawcrats/posts/?feedView=all"
                className="icon-a"
              >
                <i className="fa-brands fa-linkedin-in"></i>
              </a>
            </div>
          </div>
        </div>
        <div className="right_footer">
          <div>
            <h4>Navigation</h4>
            <a href="/">Home</a>
            <a href="#solutions-section">Solutions</a>
            <a href="#contacts-section">Contact Us</a>
            <a href="/about-us">About Us</a>
          </div>
          <div>
            <h4>Legal</h4>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                gap: "5px",
                alignItems: "center",
              }}
            >
              <a
                onClick={() => setOpenPrivacyPolicy(true)}
                style={{ textDecoration: "underline", cursor: "pointer" }}
              >
                Privacy Policy
              </a>
              <PrivacyPolicyModal
                open={openPrivacyPolicy}
                handleClose={handlePrivacyPolicy}
              />
              <LaunchIcon
                sx={{ color: isDarkMode ? "white" : "black", width: "18px" }}
                onClick={handlePrivacyPolicyPageOpen}
              />
            </div>
            <div style={{ display: "flex", flexDirection: "row", gap: "5px" }}>
              <a
                onClick={() => setOpenTermsOfService(true)}
                style={{ textDecoration: "underline", cursor: "pointer" }}
              >
                Terms of service
              </a>
              <TermsOfServiceModal
                open={openTermsOfService}
                handleClose={handleTermsOfService}
              />
              <LaunchIcon
                sx={{ color: isDarkMode ? "white" : "black", width: "18px" }}
                onClick={handleTermsOfServicePageOpen}
              />
            </div>
          </div>
          <div className="div4">
            <h4>Contact</h4>
            <a href="tel:+919870212914">
              <i className="fa-solid fa-phone"></i> +91 9870212914
            </a>
            <a href="mailto:support@Lawcrats.com">
              <i className="fa-regular fa-envelope"></i> support@lawcrats.com
            </a>
            <a
              href="https://www.google.com/maps/search/?api=1&query=149+Krishna+Enclave+Raj+Nagar+Extension+Ghaziabad+Uttar+Pradesh+201017"
              target="_blank"
              rel="noopener noreferrer"
            >
              <i className="fa-solid fa-location-dot"></i> Ghaziabad (Delhi-NCR)
            </a>
          </div>
        </div>
      </div>
      <div className="footer_bottom2">
        <p>
          We empower technology to bridge the gap between law and innovation.
          Please note that we are not a law firm.
        </p>
      </div>
      <div className="footer_bottom">
        <p>
          ©2024 Megdra Enterprises Pvt. Ltd. All rights reserved. LawCrats® are
          trademarks owned by Megdra Enterprises Pvt Ltd.
        </p>
      </div>
      <hr style={{ border: "none", borderTop: "0.5px solid #D0C6C6", marginBottom: '8px', }} />
      <div className="footer_bottom3">
        <p>
          Disclaimer: The information provided through this service, including
          any data, content, or insights generated by artificial intelligence
          (AI).
        </p>
      </div>
      <hr
        style={{
          border: "none",
          borderTop: "0.5px solid #D0C6C6",
          marginBottom: "8px",
        }}
      />
    </div>
  );
};

export default HomeFooter;
