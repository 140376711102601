// StickyFooter.jsx
import React from "react";
import "./StickyFooter.css";
import { Link } from "react-router-dom";
import img from "../../../assets/image 8.png";
import img2 from "../../../assets/image (1).png";
import img3 from "../../../assets/image 16.png";
import img4 from "../../../assets/summariser.png";

const StickyFooter = ({ isDarkMode }) => {
  return (
    <div className={`sticky-footer ${isDarkMode ? "dark-mode" : ""}`}>
      <Link to={"/law-chat-bot"} className="stickey_button">
        <img src={img} alt="" />
        <button>Assistant</button>
      </Link>

      <Link to={"/casesummariser"} className="stickey_button">
        <img src={img4} alt="" />
        <button>Summarizer</button>
      </Link>

      <Link to={"/casesearch"} className="stickey_button">
        <img src={img3} alt="" />
        <button>Explorer</button>
      </Link>

      <Link to={"/new-rad"} className="stickey_button">
        <img src={img2} alt="" />
        <button>DraftMaster</button>
      </Link>
    </div>
  );
};

export default StickyFooter;
