import React, { useState } from "react";
import HomeNavbar from "../HomeNavbar/HomeNavbar";
import HomeFooter from "../HomeFooter/HomeFooter";
import "./AboutUs.css";
import img3 from "../../../assets/bhanu.jpeg";
import img1 from "../../../assets/bhoopendra.png";
import img4 from "../../../assets/dibakar.jpeg";
import img2 from "../../../assets/pradipta.jpeg";
import img5 from "../../../assets/anand .png";
import img6 from "../../../assets/img6.png";
import nipun from "../../../assets/nipun.jpg";
import introImage from "../../../assets/aboutus.jpg";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import InstagramIcon from "@mui/icons-material/Instagram";
import XIcon from "@mui/icons-material/X";
import EmailIcon from "@mui/icons-material/Email";
import { Box, Grid, MenuItem, TextField, Typography } from "@mui/material";
import EmojiEmotionsIcon from "@mui/icons-material/EmojiEmotions";
import EmojiEventsIcon from "@mui/icons-material/EmojiEvents";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import WorkIcon from "@mui/icons-material/Work";
import GavelIcon from "@mui/icons-material/Gavel";
import SupportAgentIcon from "@mui/icons-material/SupportAgent";
import { Button, Select } from "antd";
import emailjs from "emailjs-com";
import { message } from "antd";

// ----------------------------------------------------------------------------------------------------------
const founders = [
  {
    name: "Bhoopendra Singh",
    role: "Co-Founder & CEO",
    photo: img1,
    email: "bhoopendra.singh@lawcrats.com",
    linkedin: "https://www.linkedin.com/in/bhoopendras/",
    description:
      "Bhoopendra is a visionary leader with a mission to revolutionize the legal industry by seamlessly integrating technology and law.",
    instagram: "https://www.instagram.com/lawcrats/",
    twitter: "https://x.com/LawcratsTeam",
  },
  {
    name: "Nipun Jain",
    role: "Co-Founder & Head-Corporate Affairs",
    photo: nipun,
    email: "nipun.jain@lawcrats.com",
    linkedin: "https://www.instagram.com/lawcrats/",
    description:"A visionary leader, with over 7 years of experience in sales strategy and public relations, Nipun jain is the spine of operations at Lawcrats.",
    twitter: "https://x.com/LawcratsTeam",
  },
  {
    name: "Pradipta Sundar Sahoo",
    role: "Co-Founder & CTO",
    photo: img2,
    email: "pradipta.sahoo@lawcrats.com",
    linkedin: "https://www.linkedin.com/in/pradipta-sundar-sahoo2004/",
    description:
      "Pradipta's deep expertise in software engineering drives the strength and innovation of LawCrats' technological backbone.",
    instagram: "https://www.instagram.com/lawcrats/",
    twitter: "https://x.com/LawcratsTeam",
  },
];

// ----------------------------------------------------------------------------------------------------------

const teamMembers = [
  {
    name: "Bhanu Tak",
    role: "Legal Tech Expert",
    photo: img3,
    email: "tak.bhanu@lawcrats.com",
    linkedin: "https://www.linkedin.com/in/bhanutak1310/",
    twitter: "https://x.com/LawcratsTeam",
  },
  {
    name: "Dibakar Biswas",
    role: "Software Engineer",
    photo: img4,
    email: "itsdibakar007@gmail.com",
    linkedin: "https://www.linkedin.com/in/connect-dibakar/",
    twitter: "https://x.com/LawcratsTeam",
  },
  {
    name: "Anand Kumar",
    role: "Software Engineer",
    photo: img5,
    email: "anandkumar@gmail.com",
    linkedin: "https://www.linkedin.com/in/anand-kumar0/",
    twitter: "https://x.com/LawcratsTeam",
  },
  
  {
    name: "Nikhil Kumar",
    role: "Software Engineer",
    photo: img6,
    email: "nikhil@gmail.com",
    linkedin: "https://www.linkedin.com/in/nikhil-singh-a83776256/",
    twitter: "https://x.com/LawcratsTeam",
  },
];

// ----------------------------------------------------------------------------------------------------------

const textContent = [
  {
    title: "Our Vision",
    text: "We envision a future where legal professionals can work seamlessly and efficiently, using intuitive tools that enhance decision-making and workflow. LawCrats is committed to transforming the legal landscape through innovative, accessible solutions that simplify complex tasks and boost your productivity.",
  },
  {
    title: "Our Values",
    text: "At LawCrats, integrity, transparency, and commitment to excellence form the foundation of everything we do. We believe in fostering trust and creating genuine value for our clients by adhering to the highest standards of quality and service. Our dedication to these principles guides us as we continually adapt to the evolving needs of the legal field.",
  },
  {
    title: "Our Team's Contribution",
    text: "Driven by a shared passion for legal technology, our team of experts, including Legal Professionals, Developers, and Designers, brings unmatched expertise and commitment. Every feature and solution we develop is crafted to meet the unique demands of modern legal work, empowering you to approach your challenges with confidence. Together, we aim to redefine efficiency and clarity in the legal profession.",
  },
];

// ----------------------------------------------------------------------------------------------------------

const AboutUs = () => {
  const [isDarkMode, setIsDarkMode] = useState(true);

  const handleToggleDarkMode = (darkMode) => {
    setIsDarkMode(darkMode);
  };

  const highlights = [
    {
      icon: (
        <EmojiEmotionsIcon
          fontSize="large"
          sx={{ color: isDarkMode ? "white" : "black" }}
        />
      ),
      title: "Happy Clients",
      description: "Thousands of satisfied clients",
    },
    {
      icon: (
        <EmojiEventsIcon
          fontSize="large"
          sx={{ color: isDarkMode ? "white" : "black" }}
        />
      ),
      title: "Achievement",
      description: "Numerous awards and accolades",
    },
    {
      icon: (
        <TaskAltIcon
          fontSize="large"
          sx={{ color: isDarkMode ? "white" : "black" }}
        />
      ),
      title: "Task Complete",
      description: "Over 5,000 successful cases",
    },
    {
      icon: (
        <WorkIcon
          fontSize="large"
          sx={{ color: isDarkMode ? "white" : "black" }}
        />
      ),
      title: "Experience",
      description: "20+ years of legal expertise",
    },
    {
      icon: (
        <GavelIcon
          fontSize="large"
          sx={{ color: isDarkMode ? "white" : "black" }}
        />
      ),
      title: "Qualified Lawyer",
      description: "Highly skilled and certified",
    },
    {
      icon: (
        <SupportAgentIcon
          fontSize="large"
          sx={{ color: isDarkMode ? "white" : "black" }}
        />
      ),
      title: "Dedicated Support",
      description: "24/7 support for our clients",
    },
  ];

  return (
    <>
      <HomeNavbar onToggleDarkMode={handleToggleDarkMode} />
      <div className={`aboutus-container ${isDarkMode ? "dark" : ""}`}>
        <div className="intro-section">
          <div className="intro-text">
            <h2 className="aboutus-heading">Welcome to LawCrats</h2>
            <p className="aboutparagraph">
              LawCrats is your reliable partner in navigating the complexities
              of the legal world. Our team is dedicated to provide seamless
              solutions, enabling law professionals to make informed decisions
              confidently. We bridge the gap between legal expertise and
              technology, enhancing your productivity and efficiency.LawCrats is
              here to help you overcome the challenges of modern legal work. We
              ensure smoother workflows, allowing you to be more productive and
              confident in your decisions. Trust us to elevate your legal
              capabilities in a competitive world.
            </p>
          </div>
          <div className="intro-image-container">
            <img src={introImage} alt="Intro" className="intro-image" />
          </div>
        </div>

        <Box className="highlights-container">
          <Grid container spacing={4} justifyContent="center">
            {highlights.map((highlight, index) => (
              <Grid item xs={12} sm={6} md={4} key={index}>
                <Box className="highlight-box">
                  <Box className="highlight-icon">{highlight.icon}</Box>
                  <Typography variant="h6" className="highlight-title">
                    {highlight.title}
                  </Typography>
                  <Typography variant="body2" className="highlight-description">
                    {highlight.description}
                  </Typography>
                </Box>
              </Grid>
            ))}
          </Grid>
        </Box>
        <p className="aboutparagraph"></p>
        <div className="textInfoContainer">
          {textContent.map((item, index) => (
            <div
              key={index}
              className="text-card-about"
              style={{
                backgroundColor: isDarkMode ? "#333" : "white",
                color: isDarkMode ? "white" : "black",
              }}
            >
              <div className="about-text-title">{item.title}</div>
              <hr
                style={{
                  border: "none",
                  borderTop: "0.5px solid #D0C6C6",
                  marginBottom: "12px",
                }}
              />
              <div className="about-text-content">{item.text}</div>
            </div>
          ))}
        </div>
        <h3 className="founder-heading">Our Founders</h3>
        <div className="founders-container">
          {founders.map((founder, index) => (
            <div key={index} className="founder-card">
              <img
                src={founder.photo}
                alt={`${founder.name} photo`}
                className="founder-photo"
              />
              <h4>{founder.name}</h4>
              <p>{founder.role}</p>
              <p className="founder-description">{founder.description}</p>
              <a href={founder.email} target="_blank" rel="noopener noreferrer">
                <EmailIcon />
              </a>
              <a
                href={founder.linkedin}
                target="_blank"
                rel="noopener noreferrer"
              >
                <LinkedInIcon />
              </a>
              {/* <a
                href={founder.instagram}
                target="_blank"
                rel="noopener noreferrer"
              >
                <InstagramIcon />
              </a> */}
              <a
                href={founder.twitter}
                target="_blank"
                rel="noopener noreferrer"
              >
                <XIcon />
              </a>
            </div>
          ))}
        </div>

        <h3 className="team-heading">Meet Our Team</h3>
        <div className="team-container">
          {teamMembers.map((member, index) => (
            <div key={index} className="team-card">
              <img
                src={member.photo}
                alt={`${member.name} photo`}
                className="team-photo"
              />
              <h4>{member.name}</h4>
              <p>{member.role}</p>
              <a href={member.email} target="_blank" rel="noopener noreferrer">
                <EmailIcon />
              </a>
              <a
                href={member.linkedin}
                target="_blank"
                rel="noopener noreferrer"
              >
                <LinkedInIcon />
              </a>
              <a
                href={member.twitter}
                target="_blank"
                rel="noopener noreferrer"
              >
                <XIcon />
              </a>
            </div>
          ))}
        </div>
        <iframe
          aria-label="Join Us"
          style={{ height: '500px', width: '99%', border: 'none', '-ms-overflow-style': 'none', '&::-webkit-scrollbar': { display: 'none',}, }}
          src="https://forms.zohopublic.in/LawCrats/form/JobApplication/formperma/vW2_WmdziHNmvbucHHf16IAvx6_ktfftDbucYF7AYMs"
          title="Job Application Form"
        ></iframe>
      </div>
      <HomeFooter isDarkMode={isDarkMode} />
    </>
  );
};

export default AboutUs;
