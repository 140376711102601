import React from "react";
import "./HomeTools.css";
import { useNavigate } from "react-router-dom";
import { FaRobot, FaSearch, FaFileSignature } from "react-icons/fa";
import { Link } from "react-router-dom";
import ProductShowcaseCard from "../../../CommonFiles/ProductShowCaseCard";
import gif from "../../../assets/boat-14509.gif"
import AILAWebM from "../../../assets/AILAWebM.webm"
import CSWebM from "../../../assets/CSWebM2.mp4"
import LCDMMp4 from "../../../assets/LCDMMp4.mp4"

// ----------------------------------------------------------------------------------------------------------

const productsDetails = [
  {
    gifSrc: AILAWebM,
    title: "LawCrats Legal Assistant",
    description: "The only AI-driven chatbot that truly understands your legal queries. Get detailed, well-reasoned answers to your legal questions, tailored specifically to your needs.",
    redirectURL: "/law-chat-bot",
  },
  {
    gifSrc: "",
    title: "LawCrats Legal Explorer",
    description: "Your one-stop solution for finding any legal document you need. With unparalleled search capabilities, find judgments, acts, and notifications effortlessly and get instant summaries right alongside.",
    redirectURL: "/casesearch"
  },
  {
    gifSrc: CSWebM,
    title: "LawCrats Summarizer",
    description: "The only tool you'll ever need to turn lengthy legal documents into concise, expert summaries in seconds. No more endless reading—get to the essence faster than ever.",
    redirectURL: "/casesummariser"
  },
  {
    gifSrc: LCDMMp4,
    title: "LawCrats DraftMaster",
    description: "The ultimate tool for drafting legal documents in record time. Just input your details, and let our AI create a fully customized, professional document for you—no hassle, no delay.",
    redirectURL: "/new-rad"
  }
]

// ----------------------------------------------------------------------------------------------------------

const Hometools = ({ isDarkMode }) => {
  const navigate = useNavigate();
  const handleLCBRediret = () => {
    navigate("/law-chat-bot");
  };

  const handleLERedirect = () => {
    navigate("/casesearch");
  };

  const handleLSRedirect = () =>{
    navigate("/casesummariser");
  };

  const handleDMRedirect = () =>{
    navigate("/new-rad");
  };

  return (
    <div className={`hometools-section ${isDarkMode ? "dark-mode" : ""}`}>
      <div className="hometools-background">
        <div className="hometools-content">
          <div className="hometools-header">
            <h3 className="header-title">Our Solutions</h3>
          </div>
          <div className="hometools-cards">
            <ProductShowcaseCard gifSrc={productsDetails[0].gifSrc} title={productsDetails[0].title} description={productsDetails[0].description} redirectURL={productsDetails[0].redirectURL} atLeftSide={true} />
            <ProductShowcaseCard gifSrc={productsDetails[1].gifSrc} title={productsDetails[1].title} description={productsDetails[1].description} redirectURL={productsDetails[1].redirectURL} atLeftSide={false} />
            <ProductShowcaseCard gifSrc={productsDetails[2].gifSrc} title={productsDetails[2].title} description={productsDetails[2].description} redirectURL={productsDetails[2].redirectURL} atLeftSide={true} />
            <ProductShowcaseCard gifSrc={productsDetails[3].gifSrc} title={productsDetails[3].title} description={productsDetails[3].description} redirectURL={productsDetails[3].redirectURL} atLeftSide={false} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Hometools;
