import React from 'react';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { Link as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';

const CustomBreadcrumbs = ({ links }) => {
  return (
    <Stack spacing={2}>
      <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
        {links.map((link, index) => {
          const isLast = index === links.length - 1;

          return isLast ? (
            <Typography color="text.primary" key={link.label} >
              {link.label}
            </Typography>
          ) : (
            <Link
              key={link.label}
              component={RouterLink}
              to={link.to}
              underline="hover"
              color="inherit"
            >
              {link.label}
            </Link>
          );
        })}
      </Breadcrumbs>
    </Stack>
  );
};

CustomBreadcrumbs.propTypes = {
  links: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      to: PropTypes.string.isRequired,
    })
  ).isRequired,
};

export default CustomBreadcrumbs;
