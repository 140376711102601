import React from 'react'
import { useNavigate } from 'react-router-dom';
import lcicon from "../assets/LCLogoBlack.png"

const Workspace = () => {
    const navigate = useNavigate();

    const handleSetClick = () =>{
        navigate("/")
    };
  return (
    <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100vh', gap: '50px'}}>
        <img src={lcicon} alt="" style={{height: '50px'}} onClick={handleSetClick}/>
        <span style={{padding: "16px", backgroundColor: "#eaeaea", color: "green", fontSize: "24px", borderRadius: '10px'}}>Work in progress. Stay tuned for updates, and feel free to explore our other solutions in the meantime.</span>
    </div>
  )
}

export default Workspace