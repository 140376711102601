import React, { useState, useEffect, useContext, useRef } from "react";
import { useNavigate } from "react-router-dom";
import "./Main.css";
import lclogo from "../../../assets/lc-logo-rembg.png";
import { assets } from "../../../assets/assets.js";
import { Context } from "../../../LawChatBot/context/Context.jsx";
import Popup from "../Popup/Popup.jsx";
import LCLogoBlack from "../../../assets/LCLogoBlack.png";
import FormattedContent from "../../../CommonFiles/FormattedContent.jsx";
import ProfileIconDropDown from "../../../CommonFiles/ProfileIconDropdown.jsx";
import run from "../../../LawChatBot/config/LCBConnector.js";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { message, notification } from "antd";
import { IconButton } from "@mui/material";
import SendOutlinedIcon from "@mui/icons-material/SendOutlined";
import MicNoneIcon from "@mui/icons-material/MicNone";
import Skeleton from "@mui/material/Skeleton";

const Main = () => {
  const navigate = useNavigate();
  const {
    chatHistory,
    selectedChat,
    setChatHistory,
    saveChatHistory,
  } = useContext(Context);

  const [showPremiumPopup, setShowPremiumPopup] = useState(false);
  const [showPIDropdown, setShowPIDropdown] = useState(false);
  const [input, setInput] = useState("");
  const [api, contextHolder] = notification.useNotification();
  const token = localStorage.getItem("lawCratsToken");
  const [loading, setLoading] = useState(false);

  const navRef = useRef(null);

// ----------------------------------------------------------------------------------------------------------

  useEffect(() => {
    if (selectedChat?.sessionChatHistory) {
      setChatHistory(selectedChat.sessionChatHistory);
      console.log("Chat history", selectedChat.sessionChatHistory);
    } else {
      console.warn("Selected chat history is missing or undefined.");
    }
  }, [selectedChat, setChatHistory]);

// ----------------------------------------------------------------------------------------------------------

  const openNotification = (placement) => {
    api.info({
      message: `Mic has been started!`,
      description:
        "Mic started. Speak now. It will stop automatically, handle carefully.",
      placement,
    });
  };

// ----------------------------------------------------------------------------------------------------------

  const handleKeyDown = (event) => {
    if (event.key === "Enter" && input.trim()) {
      handleSendQuestion(input);
    }
  };

// ----------------------------------------------------------------------------------------------------------

  const handleSendQuestion = async () => {
    setLoading(true);
    if (!token) {
      message.error("Please login first.");
      return;
    }

    if (!input.trim()) {
      message.error("Please enter a message.");
      return;
    }

    const currentPrompt = input;
    setChatHistory((prevHistory) => [
      ...prevHistory,
      { question: currentPrompt, isBot: false }, 
    ]);
    setInput("");

    try {
      const resultData = await run(currentPrompt);

      if (resultData) {
        setChatHistory((prevHistory) => [
          ...prevHistory,
          { answer: resultData, isBot: true },
        ]);
        if (typeof saveChatHistory === "function") {
          saveChatHistory();
        } else {
          console.error("saveChatHistory is not a function");
        }
      } else {
        message.warning("Received an unexpected response from the server.");
      }
    } catch (error) {
      message.error(
        "An error occurred while communicating with the server. Please try again."
      );
    } finally {
      setLoading(false);
    }
  };

// ----------------------------------------------------------------------------------------------------------

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        navRef?.current &&
        !navRef.current.contains(event?.target) &&
        !event?.target?.matches(".nav-img")
      ) {
        if (showPIDropdown) {
          setShowPIDropdown(false);
        }
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [showPIDropdown]);

// ----------------------------------------------------------------------------------------------------------

  const toggleDropdown = () => {
    setShowPIDropdown(!showPIDropdown);
  };

// ----------------------------------------------------------------------------------------------------------

  const handleBack = () => {
    navigate("/");
  };

// ----------------------------------------------------------------------------------------------------------

  const startSpeechRecognition = () => {
    openNotification("top");
    const recognition = new window.webkitSpeechRecognition();
    recognition.lang = "en-US";
    recognition.interimResults = false;
    recognition.maxAlternatives = 1;

    recognition.onresult = (event) => {
      const transcript = event.results[0][0].transcript;
      setInput((prevInput) =>
        prevInput ? `${prevInput} ${transcript}` : transcript
      );
    };

    recognition.onerror = (event) => {
      console.error("Error occurred in speech recognition: ", event?.error);
    };

    recognition.start();
  };

// ----------------------------------------------------------------------------------------------------------

  return (
    <div className="main">
      {contextHolder}
      <div className="nav">
        <button
          className="back-button"
          style={{ display: "flex", alignItems: "center" }}
          onClick={handleBack}
        >
          <ArrowBackIcon style={{ fontSize: "20px", marginRight: "5px" }} />
          Back
        </button>
        <img
          src={assets.user_icon}
          alt="profile-img"
          onClick={toggleDropdown}
          className="nav-img"
        />
      </div>
      <div ref={navRef}>
        <ProfileIconDropDown showProfileIconDropdown={showPIDropdown} />
      </div>
      <div className="main-container">
        <div className="greet">
          <img src={lclogo} alt="" style={{ width: "55px" }} />
          <p className="intro1">LawCrats Legal Assistant</p>
          <p className="intro2">Legal Issues? Ask Me!</p>
        </div>
        <>
          {chatHistory.map((history, index) => (
            <div key={index} className="result">
              {history.question && (
                <div className="result-title">
                  <img
                    className="imgClassUser"
                    src={assets?.user_icon}
                    alt=""
                  />
                  <p className="promptClass">{history.question}</p>
                </div>
              )}

              {history.answer && (
                <div className="result-response-part">
                  <div className="botImgContainer">
                    <img className="imgClassBot" src={LCLogoBlack} alt="" />
                  </div>
                  <div className="formatted-content-wrapper">
                    <FormattedContent text={history.answer} />
                  </div>
                </div>
              )}
            </div>
          ))}
          {loading && (
            <>
              <Skeleton variant="rectangular" height="2em" animation="wave"
                style={{
                  marginLeft: "50px",
                  marginRight: "50px",
                  marginBottom: "15px",
                }}
              />
              <Skeleton variant="rectangular" height="2em" animation="wave"
                style={{
                  marginLeft: "50px",
                  marginRight: "50px",
                  marginBottom: "15px",
                }}
              />
            </>
          )}
        </>
        <div className="main-bottom">
          <div className="search-box">
            <input
              name="input"
              onChange={(event) => setInput(event?.target?.value)}
              onKeyDown={handleKeyDown}
              value={input}
              type="text"
              placeholder="Ask Me..."
            />
            <div
              style={{
                display: "flex",
                gap: "10px",
                justifyContent: "center",
                alignItems: "center",
                marginRight: '18px',
              }}
            >
              <IconButton
                onClick={startSpeechRecognition}
                sx={{ width: "35px", height: "35px" }}
              >
                <MicNoneIcon />
              </IconButton>
              <IconButton
                onClick={handleSendQuestion}
                sx={{ width: "35px", height: "35px" }}
              >
                <SendOutlinedIcon />
              </IconButton>
            </div>
          </div>
          <div className="bottom-info">
            <button onClick={() => setShowPremiumPopup(true)}></button>
          </div>
        </div>
      </div>
      <Popup
        show={showPremiumPopup}
        onClose={() => setShowPremiumPopup(false)}
      />
    </div>
  );
};

export default Main;
