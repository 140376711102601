import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "./LoginPage.css";
import { VisibilityOutlined, VisibilityOffOutlined } from "@mui/icons-material";
import { MuiOtpInput } from "mui-one-time-password-input";
import IconButton from "@mui/material/IconButton";
import axios from "axios";
import img from "../../assets/logo.png";
import { message } from "antd";
import PrivacyPolicyModal from "../../CommonFiles/PrivacyPolicyModal";
import TermsOfServiceModal from "../../CommonFiles/TermsOfServiceModal";
import Button from '@mui/material/Button';
import img6 from "../../assets/white version.png"

import { GoogleLogin } from "@react-oauth/google";

const LoginPage = () => {
  const [currState, setCurrState] = useState("Login");
  const [isRightPanelActive, setIsRightPanelActive] = useState(false);
  const [signInData, setSignInData] = useState({
    email: "",
    password: "",
  });
  const [signUpData, setSignUpData] = useState({
    name: "",
    email: "",
    password: "",
  });

  const [FPEmail, setFPEmail] = useState("");
  const [new_password, setNew_password] = useState("");

  const [retypePassword, setRetypePassword] = useState("");
  const [retypeFPPassword, setRetypeFPPassword] = useState("");
  const [passwordMatch, setPasswordMatch] = useState(null);
  const [showPassword, setShowPassword] = useState(false);
  const [showRetypePassword, setShowRetypePassword] = useState(false);
  const [showRetypeFPPassword, setShowRetypeFPPassword] = useState(false);
  const [showSignInPassword, setShowSignInPassword] = useState(false);
  const [openOTPSection, setOpenOTPSection] = useState(false);
  const [timeLeft, setTimeLeft] = useState(120); // 2 minutes in seconds
  const [startCountdown, setStartCountdown] = useState(false);
  const [otp, setOtp] = useState("");
  const [fpotp, setFPOtp] = useState("");
  const [openPrivacyPolicy, setOpenPrivacyPolicy] = useState(false);
  const [openTermsOfUsePolicy, setOpenTermsOfUsePolicy] = useState(false);
  const [isForgotPasswordTabOpen, setIsForgotPasswordTabOpen] = useState(false);
  const [isResetPasswordTabOpen, setIsResetPasswordTabOpen] = useState(false);

  const navigate = useNavigate();

// ----------------------------------------------------------------------------------------------------------

  const handleChange = (newValue) => {
    setOtp(newValue);
  };

// ----------------------------------------------------------------------------------------------------------

  const handleFPOTPChange = (newValue) => {
    setFPOtp(newValue);
  };
// ----------------------------------------------------------------------------------------------------------

  // Toggle function for password visibility
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

// ----------------------------------------------------------------------------------------------------------

  const toggleRetypePasswordVisibility = () => {
    setShowRetypePassword(!showRetypePassword);
  };


  const toggleRetypeFPPasswordVisibility = () => {
    setShowRetypeFPPassword(!showRetypeFPPassword);
  };
// ----------------------------------------------------------------------------------------------------------

  const toggleSignInPasswordVisibility = () => {
    setShowSignInPassword(!showSignInPassword);
  };

// ----------------------------------------------------------------------------------------------------------

  // below one is for updating each details onchange
  const handleSignInDataChange = (e) => {
    setSignInData({ ...signInData, [e.target.name]: e.target.value });
  };

// ----------------------------------------------------------------------------------------------------------

  // same for this also
  const handleSignUpDataChange = (e) => {
    setSignUpData({ ...signUpData, [e.target.name]: e.target.value });
  };


// ----------------------------------------------------------------------------------------------------------

  // to handle retype password change and validate passwords
  const handleRetypePasswordChange = (e) => {
    setRetypePassword(e.target.value);
    validatePasswords(signUpData.password, e.target.value);
  };

// ----------------------------------------------------------------------------------------------------------

  // Validate that both passwords match
  const validatePasswords = (password, retypePassword) => {
    if (retypePassword === "") {
      setPasswordMatch(null);
    } else if (password === retypePassword) {
      setPasswordMatch(true);
    } else {
      setPasswordMatch(false);
    }
  };


// ----------------------------------------------------------------------------------------------------------

  // this one for transfering signup data to backend please check the endpoint below
  const handleSignupDataSubmit = async (e) => {
    e.preventDefault();
    if (!passwordMatch) {
      return message.error("Password and confirmation password do not match!");
    }
    try {
      const response = await axios.post(
        "https://d3gcxrg9olqhec.cloudfront.net/api/register/",
        {
          name: signUpData.name,
          email: signUpData.email,
          password: signUpData.password,
        }
      );
      if (response) {
        setOpenOTPSection(true);
        setStartCountdown(true);
        message.success("User created Successfully!");
      }
    } catch (error) {
      message.error("Something went wrong.Try Again!");
    }
  };

// ----------------------------------------------------------------------------------------------------------

  // handle otp submit
  const handleOtpSubmit = async (e) => {
    e.preventDefault();
    if(!otp){
      message.warning("Please enter OTP!");
      return;
    }
    try {
      const response = await axios.post(
        "https://d3gcxrg9olqhec.cloudfront.net/api/verify-otp/",
        {
          email: signUpData.email,
          otp: otp,
        }
      );

      if (response.status === 200) {
        message.success("OTP verified successfully!");
        setIsRightPanelActive(false);
        setCurrState("Login");
      } 
      else {
        message.error("OTP verification failed!");
      }
    } catch (error) {
      message.error("OTP verification failed!");
    }
  };

// ----------------------------------------------------------------------------------------------------------

  const handleFPOtpSubmit = async(e) => {
    e.preventDefault();
    if(!fpotp){
      message.warning("Please enter OTP.");
      return;
    }
    try{
      const response = await axios.post("https://d3gcxrg9olqhec.cloudfront.net/api/verify-otp/",
        {
          email: FPEmail,
          otp: fpotp,
        }
      );
      if(response.status === 200){
        message.success("OTP verified successfully!");
        setIsForgotPasswordTabOpen(false);
        setIsResetPasswordTabOpen(true);
      }
      else {
        message.error("OTP verification failed!");
      }
    }catch (error) {
      message.error("Something went wrong.Try Again!");
    }
  };
//----------------------------------------------------------------------------------------------------------

  // Countdown logic
  useEffect(() => {
    let timer;
    if (startCountdown && timeLeft > 0) {
      timer = setInterval(() => {
        setTimeLeft((prevTime) => prevTime - 1);
      }, 1000);
    }
    return () => clearInterval(timer); // Clear timer on component unmount
  }, [startCountdown, timeLeft]);


// ----------------------------------------------------------------------------------------------------------

  const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;
    return `${minutes}:${seconds < 10 ? `0${seconds}` : seconds}`;
  };

// ----------------------------------------------------------------------------------------------------------

  // this one for sending signin data to backend, check endpoint
  const handleSigninDataSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(
        "https://d3gcxrg9olqhec.cloudfront.net/api/login/",
        {
          email: signInData.email,
          password: signInData.password,
        }
      );
      if (response) {
        localStorage.setItem("lawCratsToken", response?.data?.access_token)
        message.success("Logged in successfully!");
        navigate("/");
      }
    } catch (error) { 
      message.error(`${error?.response?.data?.error}`);
    }
  };

// ----------------------------------------------------------------------------------------------------------

  const handleResendOTP = async(e) => {
    e.preventDefault();
    if (timeLeft > 0) {
      message.warning("Please wait until the countdown ends to resend the OTP.");
      return;
    }
    try{
      const response = await axios.post("https://d3gcxrg9olqhec.cloudfront.net/api/resend-otp/",
        {email: signUpData.email || FPEmail,}
      );
      if(response.status === 200){
        message.success("OTP sent successfully!");
        setTimeLeft(120);
        setStartCountdown(true);
      }
    } catch (error){
      message.error(`${error?.response?.data?.error}`);
    }

  };

// ----------------------------------------------------------------------------------------------------------

  const handleSignUpClick = () => {
    setIsRightPanelActive(true);
    const h1Element = document.querySelector(".login-logo1 .law");
    if (h1Element) {
      h1Element.style.color = "black";
    }
  };

// ----------------------------------------------------------------------------------------------------------

  const handleSignInClick = () => {
    setIsRightPanelActive(false);
    const h1Element = document.querySelector(".login-logo1 .law");
    if (h1Element) {
      h1Element.style.color = "white";
    }
  };

// ----------------------------------------------------------------------------------------------------------

  // handle privacy policy
  const handlePrivacyPolicy = () => {
    setOpenPrivacyPolicy(!openPrivacyPolicy);
  };
  const goBack = () => {
    navigate("/");
  };

// ----------------------------------------------------------------------------------------------------------

  const handleTermsOfUsePolicy = () => {
    setOpenTermsOfUsePolicy(!openTermsOfUsePolicy);
  }

// ----------------------------------------------------------------------------------------------------------

  const handleSuccess = async (response) => {
    const token = response.credential;

    try{
      const response = await axios.post("https://d3gcxrg9olqhec.cloudfront.net/api/google-auth/",
        {
          id_token: token,
        }
      );
      if (response) {
        localStorage.setItem("lawCratsToken", response?.data?.access_token)
        message.success("Logged in successfully!");
        navigate("/");
      }
    }catch (error) { 
      message.error(`${error?.response?.data?.error}`);
    }
  };

  const handleFailure = (error) => {
    message.error("Login Failed: ", error);
  };

// ----------------------------------------------------------------------------------------------------------

  const handleForgotPassword = async(e) => {
    e.preventDefault();
    try{
      const response = await axios.post("https://d3gcxrg9olqhec.cloudfront.net/api/forgot-password/",
        {email: FPEmail}
      );
      if(response.status === 200){
        message.success("OTP sent! Please verify your email.");
        setStartCountdown(true);
      }
    }catch(error){
      message.error(`${error?.response?.data?.error}`);
    }
  };

// ----------------------------------------------------------------------------------------------------------

  const handleFPEmailChange = (e) => {
    setFPEmail(e.target.value);
  };

// ----------------------------------------------------------------------------------------------------------

const handleResetPassword = async(e) => {
  e.preventDefault();
  if (!passwordMatch) {
    return message.error("Password and confirmation password do not match!");
  }
  try{
    const response = await axios.post("https://d3gcxrg9olqhec.cloudfront.net/api/reset-password/",
      {
        email: FPEmail,
        new_password: new_password,
      }
    );
    if(response.status === 200){
      message.success("Password reset successfully.");
      setIsResetPasswordTabOpen(false);
      setCurrState("Login");
    }
  }catch(error){
    message.error(`${error?.response?.data?.error}`);
  }
};

// ----------------------------------------------------------------------------------------------------------

const handleNewPasswordChange = (e) => {
  setNew_password(e.target.value);
};

// ----------------------------------------------------------------------------------------------------------

const handleRetypeFPPasswordChange = (e) => {
  setRetypeFPPassword(e.target.value);
  validatePasswords(new_password, e.target.value);
};

// ----------------------------------------------------------------------------------------------------------

  return (
    <>
      <div
        className={`container login_hide ${isRightPanelActive ? "right-panel-active" : ""
          }`}
        id="container"
      >
        <div className="login-container1">
          <div className="login-logo1" onClick={goBack}>
            <img src={isRightPanelActive? img : img6} alt="Logo" />
            <h1>
              <span className="law">Law</span>
              <span className="crats">Crats</span>
            </h1>
          </div>
        </div>

        <div className="form-container sign-up-container">
          <form
            className="dataSubmitForm"
            onSubmit={openOTPSection ? handleOtpSubmit : handleSignupDataSubmit}
          >
            {openOTPSection ? (
              <>
                <h1 className="h1Header">Email Verification</h1>
                <p
                  style={{
                    color: "white",
                    padding: "10px",
                    backgroundColor: "#3D3D3D",
                    borderRadius: "5px",
                    margin: "25px 0",
                  }}
                >
                  An OTP has been sent to your email {signUpData.email}. Please
                  enter the OTP below to verify your account.
                </p> 
                {startCountdown && timeLeft > 0 && (
                  <p
                    style={{
                      color: "red",
                      fontWeight: "bold",
                      marginBottom: "15px",
                    }}
                  >
                    Time remaining: {formatTime(timeLeft)}
                  </p>
                )}
                <MuiOtpInput
                  value={otp}
                  length={4}
                  onChange={handleChange}
                  sx={{
                    input: {
                      color: "black",
                      backgroundColor: "white",
                      width: "2rem",
                      height: "2rem",
                      fontSize: "1.2rem",
                      textAlign: "center",
                      margin: "0.2rem",
                      border: "1px solid black",
                      borderRadius: "4px",
                    },
                  }}
                />
                <p
                  style={{
                    color: "white",
                    padding: "10px",
                    backgroundColor: "#3D3D3D",
                    borderRadius: "5px",
                    margin: "20px 0",
                  }}
                >
                  Didn't receive the OTP? <span onClick={handleResendOTP} style={{ cursor: "pointer", color: "white", textDecorationLine: 'underline' }} >Resend OTP</span>
                </p>
                <button className="buttonClass" type="submit">
                  Verify OTP
                </button>
              </>
            ) : (
            <>
              <h1 className="h1Header">Create Account</h1>
              <div style={{ margin: "20px 0" }}>
              <GoogleLogin onSuccess={handleSuccess} onFailure={handleFailure} useOneTap />
              </div>
              <p
                style={{ color: "white", margin: "10px 0", fontSize: "16px" }}
              >
                Or use your email for registration
              </p>
              <input
                className="inputClass"
                name="name"
                value={signUpData.name}
                type="text"
                placeholder="Full Name"
                onChange={handleSignUpDataChange}
                required
              />
              <input
                className="inputClass"
                name="email"
                value={signUpData.email}
                type="email"
                placeholder="Email"
                onChange={handleSignUpDataChange}
                required
              />
              <div style={{ position: "relative", width: "100%" }}>
                <input
                  className="inputClass"
                  name="password"
                  value={signUpData.password}
                  type={showPassword ? "text" : "password"}
                  placeholder="Password"
                  onChange={handleSignUpDataChange}
                  style={{ width: "80%" }}
                  required
                />
                <IconButton
                  onClick={togglePasswordVisibility}
                  style={{
                    position: "absolute",
                    cursor: "pointer",
                    right: "60px",
                    top: "50%",
                    transform: "translateY(-50%)",
                    zIndex: "4",
                  }}
                >
                  {showPassword ? (
                    <VisibilityOffOutlined />
                  ) : (
                    <VisibilityOutlined />
                  )}
                </IconButton>
              </div>

              <div
                style={{
                  position: "relative",
                  width: "100%",
                  marginBottom: "10px",
                }}
              >
                <input
                  className="inputClass"
                  name="retypePassword"
                  value={retypePassword}
                  type={showRetypePassword ? "text" : "password"}
                  placeholder="Password"
                  onChange={handleRetypePasswordChange}
                  reuired
                  style={{
                    width: "80%",
                    borderColor:
                      passwordMatch !== null
                        ? passwordMatch
                          ? "green"
                          : "red"
                        : "initial",
                    borderWidth: "1.5px",
                    borderStyle: "solid",
                  }}
                  required
                />
                <IconButton
                  onClick={toggleRetypePasswordVisibility}
                  style={{
                    position: "absolute",
                    cursor: "pointer",
                    right: "60px",
                    top: "50%",
                    transform: "translateY(-50%)",
                    zIndex: "4",
                  }}
                >
                  {showRetypePassword ? (
                    <VisibilityOffOutlined />
                  ) : (
                    <VisibilityOutlined />
                  )}
                </IconButton>
              </div>
              <p style={{ margin: "12px 0", color: "white", fontSize: "12px" }}>
                By continuing, you agree to LawCrats’s {" "}
                <span
                  onClick={handleTermsOfUsePolicy}
                  style={{
                    cursor: "pointer",
                    textDecorationLine: "underline",
                  }}
                >
                  Terms of Service
                </span>
                {" "} and {" "}
                <span
                  onClick={handlePrivacyPolicy}
                  style={{
                    cursor: "pointer",
                    textDecorationLine: "underline",
                  }}
                >
                  Privacy Policy
                </span>
                .
              </p>
              <button className="buttonClass" type="submit">
                Sign Up
              </button>
              <TermsOfServiceModal
                open={openTermsOfUsePolicy}
                handleClose={handleTermsOfUsePolicy}
              />
              <PrivacyPolicyModal
                open={openPrivacyPolicy}
                handleClose={handlePrivacyPolicy}
              />
            </>
             )}
          </form>
        </div>
        <div className="form-container sign-in-container">
          {isForgotPasswordTabOpen?(
              <div className="dataSubmitForm">
                <h1 className="h1Header">Email Verification</h1>
                <input
                  className="inputClass"
                  name="forgotEmail"
                  type="email"
                  placeholder="Email"
                  onChange={handleFPEmailChange}
                  required
                />
                <button className="buttonClass" onClick={handleForgotPassword} style={{marginBottom: '20px'}}>
                  Send OTP
                </button>
                <hr style={{ border: "none", borderTop: "1px solid #D0C6C6", width: '90%', marginBottom: '20px', }} />
                {startCountdown && timeLeft > 0 && (
                  <p
                    style={{
                      color: "red",
                      fontWeight: "bold",
                      marginBottom: "15px",
                    }}
                  >
                    Time remaining: {formatTime(timeLeft)}
                  </p>
                )}
                <MuiOtpInput
                  value={fpotp}
                  length={4}
                  onChange={handleFPOTPChange}
                  sx={{
                    input: {
                      color: "black",
                      backgroundColor: "white",
                      width: "2rem",
                      height: "2rem",
                      fontSize: "1.2rem",
                      textAlign: "center",
                      margin: "0.2rem",
                      border: "1px solid black",
                      borderRadius: "4px",
                    },
                  }}
                />
                <p
                  style={{
                    color: "white",
                    padding: "10px",
                    backgroundColor: "#3D3D3D",
                    borderRadius: "5px",
                    margin: "20px 0",
                  }}
                >
                  Didn't receive the OTP? <span onClick={handleResendOTP} style={{ cursor: "pointer", color: "white", textDecorationLine: 'underline' }} >Resend OTP</span>
                </p>
                <button className="buttonClass" onClick={handleFPOtpSubmit}>
                  Verify OTP
                </button>
              </div>
            ):isResetPasswordTabOpen?(
              <div className="dataSubmitForm">
                <h1 className="h1Header">Reset Password</h1>
                <div style={{ position: "relative", width: "100%" }}>
                  <input
                    className="inputClass"
                    name="new_password"
                    value={new_password}
                    type={showPassword ? "text" : "password"}
                    placeholder="Password"
                    onChange={handleNewPasswordChange}
                    style={{ width: "80%" }}
                    required
                  />
                  <IconButton
                    onClick={togglePasswordVisibility}
                    style={{
                      position: "absolute",
                      cursor: "pointer",
                      right: "60px",
                      top: "50%",
                      transform: "translateY(-50%)",
                      zIndex: "4",
                    }}
                  >
                    {showPassword ? (
                      <VisibilityOffOutlined />
                    ) : (
                      <VisibilityOutlined />
                    )}
                  </IconButton>
                </div>

                <div style={{ position: "relative", width: "100%", marginBottom: "10px", }} >
                  <input
                    className="inputClass"
                    name="retypePassword"
                    value={retypeFPPassword}
                    type={showRetypeFPPassword ? "text" : "password"}
                    placeholder="Re-type Password"
                    onChange={handleRetypeFPPasswordChange}
                    reuired
                    style={{
                      width: "80%",
                      borderColor:
                        passwordMatch !== null
                          ? passwordMatch
                            ? "green"
                            : "red"
                          : "initial",
                      borderWidth: "1px",
                      borderStyle: "solid",
                    }}
                    required
                  />
                  <IconButton
                    onClick={toggleRetypeFPPasswordVisibility}
                    style={{
                      position: "absolute",
                      cursor: "pointer",
                      right: "60px",
                      top: "50%",
                      transform: "translateY(-50%)",
                      zIndex: "4",
                    }}
                  >
                    {showRetypePassword ? (
                      <VisibilityOffOutlined />
                    ) : (
                      <VisibilityOutlined />
                    )}
                  </IconButton>
                </div>
                <button disabled={!passwordMatch} className="buttonClass" onClick={handleResetPassword}>
                  Submit
                </button>
              </div>
            ):(
              <form className="dataSubmitForm" onSubmit={handleSigninDataSubmit}>
              <h1 className="h1Header">Log In to Your Account</h1>
              <div style={{ margin: "20px 0" }}>
                <GoogleLogin onSuccess={handleSuccess} onFailure={handleFailure} useOneTap />
              </div>
              <span
                style={{ color: "white", margin: "10px 0", fontSize: "16px" }}
              >
                Or login with your email ID
              </span>
              <input
                className="inputClass"
                name="email"
                value={signInData.email}
                type="email"
                placeholder="Email"
                onChange={handleSignInDataChange}
                required
              />
              <div
                style={{
                  position: "relative",
                  width: "100%",
                  marginBottom: "10px",
                }}
              >
                <input
                  className="inputClass"
                  name="password"
                  value={signInData.password}
                  type={showSignInPassword ? "text" : "password"}
                  placeholder="Password"
                  onChange={handleSignInDataChange}
                  reuired
                  style={{ width: "80%", paddingRight: "40px" }}
                  required
                />
                <IconButton
                  onClick={toggleSignInPasswordVisibility}
                  style={{
                    position: "absolute",
                    cursor: "pointer",
                    right: "60px",
                    top: "50%",
                    transform: "translateY(-50%)",
                    zIndex: "4",
                  }}
                >
                  {showSignInPassword ? (
                    <VisibilityOffOutlined />
                  ) : (
                    <VisibilityOutlined />
                  )}
                </IconButton>
              </div>
              <span
                onClick={()=>{setIsForgotPasswordTabOpen(true);}}
                style={{ fontSize: "14px", color: "white", marginBottom: "10px", cursor:'pointer', textDecorationLine: 'underline' }}
              >
                Forgot password?
              </span>
              <button className="buttonClass" type="submit">
                Log In
              </button>
            </form>
            )}
          </div>
        <div className="overlay-container">
          <div className="overlay">
            <div className="overlay-panel overlay-left">
              <h1 className="h1Header">Hello Friend !</h1>
              <p style={{ margin: "20px 0", fontWeight: 400, fontSize: 18 }}>
                Please provide the information to register your account.
              </p>
              <p
                style={{ marginBottom: "15px", fontWeight: 400, fontSize: 16 }}
              >
                Already have an account! Login
              </p>
              <button className="ghost" id="signIn" onClick={handleSignInClick}>
                Log In
              </button>
            </div>
            <div className="overlay-panel overlay-right">
              <h1 className="h1Header">Welcome Back !</h1>
              <p style={{ margin: "20px 0", fontWeight: 400, fontSize: 18 }}>
                Don’t have an account? Sign up
              </p>
              <button className="ghost" id="signUp" onClick={handleSignUpClick}>
                Sign Up
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="login-container below850">
        {/* Logo at the top */}
        <div className="login-logo" onClick={goBack}>
          <img src={img} alt="Logo" />
          <h1>
            <span className="login-law" >Law</span>
            <span className="login-crats">Crats</span>
          </h1>
        </div>
        <div className="login_below_box">
          <div className="login_top">
            <div className="login-popup-inputs">
              {currState === "Login" ? (
                <div className="common-mv-login-signup">
                  <div style={{ width: '100%', display: 'flex', flexDirection: 'column', gap: '5px' }}>
                    <h2 className="h1HeaderMobi">Login</h2>
                    <hr style={{ border: "none", borderTop: "1.5px solid #E0DDDD", width: '100%' }} />
                  </div>
                  <GoogleLogin onSuccess={handleSuccess} onFailure={handleFailure} useOneTap />
                  <input
                    className="common-mv-input"
                    type="email"
                    name="email"
                    placeholder="Your Email"
                    onChange={handleSignInDataChange}
                    required
                  />
                  <div style={{ position: "relative", width: "100%" }}>
                    <input
                      className="common-mv-input"
                      type={showSignInPassword ? "text" : "password"}
                      name="password"
                      placeholder="Password"
                      onChange={handleSignInDataChange}
                      required
                    />
                    <IconButton
                      onClick={toggleSignInPasswordVisibility}
                      style={{
                        position: "absolute",
                        cursor: "pointer",
                        right: "10px",
                        top: "50%",
                        transform: "translateY(-50%)",
                        zIndex: "4",
                      }}
                    >
                      {showSignInPassword ? (
                        <VisibilityOffOutlined />
                      ) : (
                        <VisibilityOutlined />
                      )}
                    </IconButton>
                  </div>
                  <Button onClick={handleSigninDataSubmit} variant="contained" sx={{ textTransform: 'none', backgroundColor: 'black', '@media (max-width: 600px)': { fontSize: '12px', padding: '6px 12px' } }}>Login</Button>
                  <p onClick={()=>{setIsForgotPasswordTabOpen(true); setCurrState(null);}} style={{ cursor: "pointer", color: "black", textDecorationLine: 'underline' }}>
                    Forgot password?
                  </p>
                  <p>
                    Don't have an account? Create a new one!{" "}
                    <span onClick={() => setCurrState("Sign Up")} style={{ cursor: 'pointer', color: 'blue' }}>Click here</span>
                  </p>
                </div>
              ) : (
                openOTPSection ? (
                  <div className="common-mv-login-signup">
                    <div style={{ width: '100%', display: 'flex', flexDirection: 'column', gap: '5px' }}>
                      <h2 className="h1HeaderMobi">Email Verification</h2>
                      <hr style={{ border: "none", borderTop: "1.5px solid #E0DDDD", width: '100%' }} />
                    </div>
                    <p style={{ color: "black" }}>
                      An OTP has been sent to your email -<br />
                      <div style={{ fontFamily: "'Courier New', Courier, monospace", backgroundColor: "#f0f4f9", borderRadius: '2px', color: '#808080', padding: '6px', margin: '4px 0', justifyContent: 'center', display: 'flex' }}>{signUpData.email}</div>
                      Please enter the OTP below to verify your account.
                    </p>
                    {startCountdown && timeLeft > 0 && (
                      <p style={{ color: "red", fontWeight: "bold", marginBottom: "15px" }}>
                        Time remaining: {formatTime(timeLeft)}
                      </p>
                    )}
                    <MuiOtpInput
                      value={otp}
                      length={4}
                      onChange={handleChange}
                      sx={{
                        input: {
                          color: "black",
                          backgroundColor: "white",
                          width: "2rem",
                          height: "2rem",
                          fontSize: "1.2rem",
                          textAlign: "center",
                          margin: "0.2rem",
                          border: "1px solid black",
                          borderRadius: "4px",
                        },
                      }}
                    />
                    <Button onClick={handleFPOtpSubmit} variant="contained" sx={{ textTransform: 'none', backgroundColor: 'black', '@media (max-width: 600px)': { fontSize: '12px', padding: '6px 12px' } }}>Verify OTP</Button>
                    <p style={{ color: "black", padding: "5px" }}>
                      Didn't receive the OTP?{" "}
                      <span onClick={handleResendOTP} style={{ cursor: "pointer", color: "#a0a0a0", textDecorationLine: 'underline' }}>Resend OTP</span>
                    </p>
                  </div>
                ) : isForgotPasswordTabOpen ? (
                  <div className="common-mv-login-signup">
                    <div style={{ width: '100%', display: 'flex', flexDirection: 'column', gap: '5px' }}>
                      <h1 className="h1HeaderMobi">Email Verification</h1>
                      <hr style={{ border: "none", borderTop: "1.5px solid #E0DDDD", width: '100%' }} />
                    </div>
                    <input
                      className="common-mv-input"
                      name="forgotEmail"
                      type="email"
                      placeholder="Email"
                      onChange={handleFPEmailChange}
                      required
                    />
                    <Button onClick={handleForgotPassword} variant="contained" sx={{ textTransform: 'none', backgroundColor: 'black', '@media (max-width: 600px)': { fontSize: '12px', padding: '6px 12px' } }}>
                      Send OTP
                    </Button>
                    <hr style={{ border: "none", borderTop: "1px solid #D0C6C6", width: '90%', marginBottom: '20px', }} />
                    {startCountdown && timeLeft > 0 && (
                      <p
                        style={{
                          color: "red",
                          fontWeight: "bold",
                          marginBottom: "15px",
                        }}
                      >
                        Time remaining: {formatTime(timeLeft)}
                      </p>
                    )}
                    <MuiOtpInput
                      value={fpotp}
                      length={4}
                      onChange={handleFPOTPChange}
                      sx={{
                        input: {
                          color: "black",
                          backgroundColor: "white",
                          width: "1.2rem",
                          height: "1.2rem",
                          fontSize: "1rem",
                          textAlign: "center",
                          margin: "0.2rem",
                          border: "1px solid black",
                          borderRadius: "4px",
                        },
                      }}
                    />
                    <p>
                      Didn't receive the OTP? <span onClick={handleResendOTP} style={{ cursor: "pointer", textDecorationLine: 'underline' }} >Resend OTP</span>
                    </p>
                    <Button onClick={handleFPOtpSubmit} variant="contained" sx={{ textTransform: 'none', backgroundColor: 'black', '@media (max-width: 600px)': { fontSize: '12px', padding: '6px 12px' } }}>
                      Verify OTP
                    </Button>
                  </div>
                ) : isResetPasswordTabOpen ? (
                  <div className="common-mv-login-signup">
                    <div style={{ width: '100%', display: 'flex', flexDirection: 'column', gap: '5px' }}>
                      <h1 className="h1HeaderMobi">Reset Password</h1>
                      <hr style={{ border: "none", borderTop: "1.5px solid #E0DDDD", width: '100%' }} />
                    </div>
                    <div style={{ position: "relative", width: "100%" }}>
                      <input
                        className="common-mv-input"
                        name="new_password"
                        value={new_password}
                        type={showPassword ? "text" : "password"}
                        placeholder="Password"
                        onChange={handleNewPasswordChange}
                        required
                      />
                      <IconButton
                        onClick={togglePasswordVisibility}
                        style={{
                          position: "absolute",
                          cursor: "pointer",
                          right: "10px",
                          top: "50%",
                          transform: "translateY(-50%)",
                          zIndex: "4",
                        }}
                      >
                        {showPassword ? (
                          <VisibilityOffOutlined />
                        ) : (
                          <VisibilityOutlined />
                        )}
                      </IconButton>
                    </div>

                    <div style={{ position: "relative", width: "100%", marginBottom: "10px", }} >
                      <input
                        className="common-mv-input"
                        name="retypePassword"
                        value={retypeFPPassword}
                        type={showRetypeFPPassword ? "text" : "password"}
                        placeholder="Re-type Password"
                        onChange={handleRetypeFPPasswordChange}
                        reuired
                        style={{
                          borderColor:
                            passwordMatch !== null
                              ? passwordMatch
                                ? "green"
                                : "red"
                              : "initial",
                          borderWidth: "1px",
                          borderStyle: "solid",
                        }}
                        required
                      />
                      <IconButton
                        onClick={toggleRetypeFPPasswordVisibility}
                        style={{
                          position: "absolute",
                          cursor: "pointer",
                          right: "10px",
                          top: "50%",
                          transform: "translateY(-50%)",
                          zIndex: "4",
                        }}
                      >
                        {showRetypeFPPassword ? (
                          <VisibilityOffOutlined />
                        ) : (
                          <VisibilityOutlined />
                        )}
                      </IconButton>
                    </div>
                    <button className="buttonClass" onClick={handleResetPassword}>
                      Submit
                    </button>
                  </div>
                ) : (
                  <div className="common-mv-login-signup">
                    <div style={{ width: '100%', display: 'flex', flexDirection: 'column', gap: '5px' }}>
                      <h2 className="h1HeaderMobi">Signup</h2>
                      <hr style={{ border: "none", borderTop: "1.5px solid #E0DDDD", width: '100%' }} />
                    </div>
                    <GoogleLogin onSuccess={handleSuccess} onFailure={handleFailure} useOneTap />
                    <input
                      className="common-mv-input"
                      type="text"
                      name="name"
                      placeholder="Your Name"
                      onChange={handleSignUpDataChange}
                      required
                    />
                    <input
                      className="common-mv-input"
                      type="email"
                      name="email"
                      placeholder="Your Email"
                      onChange={handleSignUpDataChange}
                      required
                    />
                    <div style={{ position: "relative", width: "100%" }}>
                      <input
                        className="common-mv-input"
                        type={showPassword ? "text" : "password"}
                        name="password"
                        placeholder="Password"
                        onChange={handleSignUpDataChange}
                        required
                      />
                      <IconButton
                        onClick={togglePasswordVisibility}
                        style={{
                          position: "absolute",
                          cursor: "pointer",
                          right: "10px",
                          top: "50%",
                          transform: "translateY(-50%)",
                          zIndex: "4",
                        }}
                      >
                        {showPassword ? (
                          <VisibilityOffOutlined />
                        ) : (
                          <VisibilityOutlined />
                        )}
                      </IconButton>
                    </div>
                    <div style={{ position: "relative", width: "100%" }}>
                      <input
                        className="common-mv-input"
                        type={showRetypePassword? "text" : "password"}
                        name="retypePassword"
                        placeholder="Re-type Password"
                        onChange={handleRetypePasswordChange}
                        required
                        style={{
                          borderColor:
                            passwordMatch !== null
                              ? passwordMatch
                                ? "green"
                                : "red"
                              : "initial",
                          borderWidth: "1.5px",
                          borderStyle: "solid",
                        }}
                      />
                      <IconButton
                        onClick={toggleRetypePasswordVisibility}
                        style={{
                          position: "absolute",
                          cursor: "pointer",
                          right: "10px",
                          top: "50%",
                          transform: "translateY(-50%)",
                          zIndex: "4",
                        }}
                      >
                        {showRetypePassword ? (
                          <VisibilityOffOutlined />
                        ) : (
                          <VisibilityOutlined />
                        )}
                      </IconButton>
                    </div>
                    <p>
                      By continuing, you agree to LawCrats’s{" "}
                      <span onClick={handleTermsOfUsePolicy} style={{ cursor: "pointer", textDecorationLine: "underline", color: 'black' }}>Terms of Service</span>{" "}
                      and{" "}
                      <span onClick={handlePrivacyPolicy} style={{ cursor: "pointer", textDecorationLine: "underline", color: 'black' }}>Privacy Policy</span>.
                    </p>
                    <Button onClick={handleSignupDataSubmit} disabled={!passwordMatch} variant="contained" sx={{ textTransform: 'none', backgroundColor: 'black', '@media (max-width: 600px)': { fontSize: '12px', padding: '6px 12px' } }}>Create account</Button>
                    <p style={{marginBottom: "15px"}}>
                      Already have an account?{" "}
                      <span onClick={() => setCurrState("Login")} style={{ cursor: 'pointer', color: 'blue' }}>Login here</span>
                    </p>
                  </div>
                )
              )}
            </div>
          </div>
        </div>
        <TermsOfServiceModal
          open={openTermsOfUsePolicy}
          handleClose={handleTermsOfUsePolicy}
        />
        <PrivacyPolicyModal
          open={openPrivacyPolicy}
          handleClose={handlePrivacyPolicy}
        />
      </div>
    </>
  );
};

export default LoginPage;
